<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    >
    </BaseBreadcrumb>
    <v-card class="mt-4">
      <v-card-title class="text-h6">Planes de suscripción</v-card-title>
      <v-card-text>
        <p class="text-body-2">
          Elija el plan de suscripción que más se ajuste a sus necesidades
        </p>
      </v-card-text>
      <v-subheader>Planes de movilidad</v-subheader>
      <v-row dense>
        <v-card
          :class="{
            'plan-card': true,
            selected: plan.membershipPlanCode === currentPlanId
          }"
          v-for="plan in availablePlans.filter(p => p.userLevel === 'Mobility')"
          :key="plan.membershipPlanCode"
          @click.prevent="purchase(plan.membershipPlanCode)"
        >
          <v-card-title class="text-h5">{{
            plan.membershipPlanName
          }}</v-card-title>
          <v-card-text>
            <p>Firmas: {{ plan.creditAmount }}</p>
            <p>Renovación cada {{ plan.monthAmount }} meses.</p>
            <p>
              Precio: {{ plan.price == 0 ? "Gratuito" : plan.price + "&euro;" }}
            </p>
          </v-card-text>
        </v-card>
      </v-row>
      <v-subheader>Planes de empresa</v-subheader>
      <v-row dense>
        <v-card
          :class="{
            'plan-card': true,
            selected: plan.membershipPlanCode === currentPlanId
          }"
          v-for="plan in availablePlans.filter(
            p => p.userLevel === 'Corporate'
          )"
          :key="plan.membershipPlanCode"
          @click.prevent="purchase(plan.membershipPlanCode)"
        >
          <v-card-title class="text-h5">{{
            plan.membershipPlanName
          }}</v-card-title>
          <v-card-text>
            <p>Firmas: {{ plan.creditAmount }}</p>
            <p>Renovación cada {{ plan.monthAmount }} meses.</p>
            <p>
              Precio: {{ plan.price == 0 ? "Gratuito" : plan.price + "&euro;" }}
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card>
    <v-card class="mt-4">
      <v-card-title class="text-h6">Paquetes de créditos</v-card-title>
      <v-card-text>
        <p class="text-body-2">Contrate paquetes de créditos adicionales</p>
      </v-card-text>
      <v-row dense>
        <v-card
          :class="{
            'plan-card': true,
            selected: plan.membershipPlanCode === currentPlanId
          }"
          v-for="plan in creditPackages"
          :key="plan.membershipPlanCode"
          @click="purchase(plan.membershipPlanCode)"
        >
          <v-card-title class="text-h5">{{
            plan.membershipPlanName
          }}</v-card-title>
          <v-card-text>
            <p>Firmas: {{ plan.creditAmount }}</p>
            <p>
              Precio: {{ plan.price == 0 ? "Gratuito" : plan.price + "&euro;" }}
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Axios from "axios";

import { PURCHASE_ITEM_TYPE } from "@/utils/constants";
import { mapActions } from "vuex";

export default {
  name: "AvailablePlans",
  data: () => ({
    page: {
      title: "Planes de suscripción"
    },
    breadcrumbs: [
      {
        text: "Planes de suscripción",
        disabled: true
      }
    ],
    availablePlans: [],
    creditPackages: [],
    currentPlanId: null
  }),
  methods: {
    ...mapActions({
      setPurchasingItem: "purchase/setPurchasingItem"
    }),
    purchase(planId) {
      const plan = this.availablePlans
        .concat(this.creditPackages)
        .find(plan => plan.membershipPlanCode === planId);
      this.setPurchasingItem({
        planId,
        name: plan.membershipPlanName,
        price: plan.price,
        itemType: plan.monthAmount
          ? PURCHASE_ITEM_TYPE.SUBSCRIPTION
          : PURCHASE_ITEM_TYPE.CREDITS_PACKAGE
      });
      this.$router.push({ name: "Checkout" });
    }
  },
  beforeRouteEnter(to, from, next) {
    const getCurrentPlan = Axios.get("purchase/currentPlan");
    const getAvailablePlans = Axios.get("purchase/availablePlans");
    Promise.all([getCurrentPlan, getAvailablePlans]).then(
      ([currentPlanResponse, availablePlansResponse]) => {
        next(vm => {
          vm.currentPlanId = currentPlanResponse.data;
          vm.availablePlans = availablePlansResponse.data.filter(
            plan => plan.monthAmount
          );
          vm.creditPackages = availablePlansResponse.data.filter(
            plan => !plan.monthAmount
          );
        });
      }
    );
  }
};
</script>

<style lang="scss" scoped>
.plan-card {
  border-radius: 10%;
  background-color: #eee;
  margin: 0 0 20px 20px;
  &.selected {
    border: 8px solid #a72828;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Planes de suscripción")]),_c('v-card-text',[_c('p',{staticClass:"text-body-2"},[_vm._v(" Elija el plan de suscripción que más se ajuste a sus necesidades ")])]),_c('v-subheader',[_vm._v("Planes de movilidad")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.availablePlans.filter(function (p) { return p.userLevel === 'Mobility'; })),function(plan){return _c('v-card',{key:plan.membershipPlanCode,class:{
          'plan-card': true,
          selected: plan.membershipPlanCode === _vm.currentPlanId
        },on:{"click":function($event){$event.preventDefault();return _vm.purchase(plan.membershipPlanCode)}}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(plan.membershipPlanName))]),_c('v-card-text',[_c('p',[_vm._v("Firmas: "+_vm._s(plan.creditAmount))]),_c('p',[_vm._v("Renovación cada "+_vm._s(plan.monthAmount)+" meses.")]),_c('p',[_vm._v(" Precio: "+_vm._s(plan.price == 0 ? "Gratuito" : plan.price + "€")+" ")])])],1)}),1),_c('v-subheader',[_vm._v("Planes de empresa")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.availablePlans.filter(
          function (p) { return p.userLevel === 'Corporate'; }
        )),function(plan){return _c('v-card',{key:plan.membershipPlanCode,class:{
          'plan-card': true,
          selected: plan.membershipPlanCode === _vm.currentPlanId
        },on:{"click":function($event){$event.preventDefault();return _vm.purchase(plan.membershipPlanCode)}}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(plan.membershipPlanName))]),_c('v-card-text',[_c('p',[_vm._v("Firmas: "+_vm._s(plan.creditAmount))]),_c('p',[_vm._v("Renovación cada "+_vm._s(plan.monthAmount)+" meses.")]),_c('p',[_vm._v(" Precio: "+_vm._s(plan.price == 0 ? "Gratuito" : plan.price + "€")+" ")])])],1)}),1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Paquetes de créditos")]),_c('v-card-text',[_c('p',{staticClass:"text-body-2"},[_vm._v("Contrate paquetes de créditos adicionales")])]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.creditPackages),function(plan){return _c('v-card',{key:plan.membershipPlanCode,class:{
          'plan-card': true,
          selected: plan.membershipPlanCode === _vm.currentPlanId
        },on:{"click":function($event){return _vm.purchase(plan.membershipPlanCode)}}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(plan.membershipPlanName))]),_c('v-card-text',[_c('p',[_vm._v("Firmas: "+_vm._s(plan.creditAmount))]),_c('p',[_vm._v(" Precio: "+_vm._s(plan.price == 0 ? "Gratuito" : plan.price + "€")+" ")])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }